import React from "react";
import { Row } from "reactstrap";
import TeamRosterCard from "./components/TeamRosterIndex";

const TeamRoster = () => {
  return (
    <Row>
      <TeamRosterCard />
    </Row>
  );
};

export default TeamRoster;
