export const TABLE_COLUMNS = [
  {
    Header: "Program Name",
    accessor: "name",
  },
  {
    Header: "Requested by",
    accessor: "",
    Cell: ({ cell }) => {
      return (
        <span>
          {cell.row.original?.requestor_first_name}
          {cell.row.original?.requestor_last_name}
        </span>
      );
    },
  },
  {
    Header: "Recipient",
    accessor: "",
    Cell: ({ cell }) => {
      return (
        <span>
          {cell.row.original?.participant_first_name}
          {cell.row.original?.participant_last_name}
        </span>
      );
    },
  },
  {
    Header: "Approved by",
    accessor: "approved_by",
    Cell: ({ cell, values }) => {
      return (
        <span>
          {values?.requestor_first_name}
          {values?.requestor_last_name}
        </span>
      );
    },
  },
  {
    Header: "Event Name",
    accessor: "event_name",
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ value }) => {
      return <span>{value?.toFixed(2)}</span>;
    },
  },
  {
    Header: "Scheduled Date",
    accessor: "scheduled_date",
    Cell: ({ value }) => {
      let date = new Date(value).toLocaleDateString("en-US", {});
      return <span>{date}</span>;
    },
  },
  {
    Header: "Date Created",
    accessor: "created_at",
    Cell: ({ value }) => {
      let date = new Date(value).toLocaleDateString("en-US", {});
      return <span>{date}</span>;
    },
  },
  {
    Header: "Budgets Available",
    accessor: "budgets_available",
  },
];

const setIconPath = (icon) => {
  const path = process.env.REACT_APP_API_STORAGE_URL + "/" + icon;
  return path;
}
export const HISTORY_COLUMNS = [
  {
    Header: "Event",
    accessor: "name",
  },
  {
    Header: "Notes",
    accessor: "notes",
  },
  {
    Header: "Restrictions",
    accessor: "restrictions",
  },
  {
    Header: "Referrer",
    accessor: "referrer",
  },
  {
    Header: "Date",
    accessor: "journal_event_timestamp",
    Cell: ({ row, value }) => {
      return `${new Date(value).toLocaleDateString("en-US", {})}`;
    },
  },
  {
    Header: "Reference Document",
    accessor: "",
  },
  {
    Header: "Points",
    accessor: "amount",
  },
  {
    Header: "",
    accessor: "icon",
    // align: "center",
    Cell: ({ row, value }) => (
      !value ? '' : <img height={50} src={setIconPath(value)} alt="icons" />
    ),
  }
];
