import React from "react";
import {Modal, FormGroup, Button, Label, Spinner} from "reactstrap";
import CloseIcon from "mdi-react/CloseIcon";
import {Form, Field} from "react-final-form";

const AwardApprovalPopup = ({
                              isOpen,
                              setOpen,
                              toggle,
                              inviteApprovalParticipants = [],
                              statusName,
                              rejection_notes,
                              isShow = false,
                              onSubmit,
                              loading,
                            }) => {
  const totalAmount = inviteApprovalParticipants?.reduce(
    (totalAmount, award) => totalAmount + award.transaction_amount,
    0
  );

  return (
    <Modal
      className={`program-settings modal-lg padded`}
      isOpen={isOpen}
      toggle={() => setOpen(true)}
    >
      <div className="close cursor-pointer">
        <CloseIcon onClick={toggle} size={30}/>
      </div>
      <div className="right p-2">
        <h4 className="bg-secondary rounded text-white p-2">
          {statusName === "approved"
            ? `Are you sure you want to Approve ${inviteApprovalParticipants.length === 1 ? inviteApprovalParticipants.length + ' recipient' : ' recipients'} ?`
            : `Are you sure you want to Deny ${inviteApprovalParticipants.length === 1 ? inviteApprovalParticipants.length + 'recipient' : ' recipients'} ?`}{" "}
        </h4>
        {!isShow && (
          <div>
            <div className="d-flex m-1">
              <h5>Selected Users: </h5> <span>(Name, Amount)</span>
            </div>
            <div>
              {inviteApprovalParticipants?.map((participant, i) => {
                return (
                  <div key={participant.id}>
                    <div className="m-3">
                      <ul>
                        <li>
                          {participant.user_fname}{" "}
                          {participant.user_lname}, $
                          {participant.transaction_amount}
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
            <h6
              className="m-1 mt-2"
              style={{fontSize: "18px", fontWeight: "bold"}}
            >
              Total Amount: $ {totalAmount}
            </h6>
          </div>
        )}

        <Form
          onSubmit={onSubmit}
          initialValues={{rejection_note: rejection_notes}}
        >
          {({handleSubmit, form, submitting, pristine, values}) => {
            // console.log(values)
            return (
              <form
                className="form d-flex flex-column justify-content-evenly mt-4 p-2"
                onSubmit={handleSubmit}
              >
                {statusName === "reject" && (
                  <>
                    <Label className="text-bold">Rejection Note:</Label>
                    <Field name="rejection_note">
                      {({input, meta}) => (
                        <FormGroup>
                          <textarea {...input} className="w-50"/>
                          {meta.touched && meta.error && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </FormGroup>
                      )}
                    </Field>
                  </>
                )}
                <hr/>
                <div className="d-flex justify-content-end">
                  {!loading ? (
                    <Button color="primary" type="submit">
                        {statusName === "approved" ? 'Approve' : 'Reject' }
                    </Button>
                  ) : (
                    <Spinner size="md" color="primary">
                      Loading...
                    </Spinner>
                  )}
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </Modal>
  );
};

export default AwardApprovalPopup;
