import React, { useState, useEffect, useCallback } from "react";
import {connect} from 'react-redux';
import TabNav from "./components/Tabnav";
import { useNavigate, useLocation } from "react-router-dom";
import {Themed} from '@/theme'
import {getLeaderboards} from '@/services/program/getLeaderboards'
import { getCascadingApprovalsCount } from "@/services/program/budget";

let ParticipantTabNavsBase = ({program}) => {
  const [leaderboardCount, setLeaderboardCount] = useState(0);
  useEffect(() => {
    if( program?.id ) {
      getLeaderboards(program.organization_id, program.id, true)
      .then( count => {
        setLeaderboardCount(count)
      })
    }
    return () => {
    }
  }, []);
  return <Themed leaderboardCount={leaderboardCount} component={'ParticipantTabnav'} />
};

ParticipantTabNavsBase =  connect((state) => ({
  program: state.program
}))(ParticipantTabNavsBase);

export const ParticipantTabNavs = () => {
  return <ParticipantTabNavsBase />
};

let MANAGER_ITEMS = [
      { title: "Dashboard", icon: "dashboard", to: "/manager/home" }
  ];

let ManagerTabNavsTmp = ({organization, program, rootProgram}) => {
  const [cascadingApprovalsCount,setCascadingApprovalsCount] = useState(0)
  
  const fetchCascadingApprovalsCount = useCallback(() => {
    getCascadingApprovalsCount(organization, program)
      .then((res) => {
        if (res.status === 200) {
          setCascadingApprovalsCount(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [organization, program, rootProgram]);
  
  const [managerItems,setManagerItems] = useState(MANAGER_ITEMS);
  const [path, setPath] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let path = location?.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    setPath(path);
  }, [location]);
  let navigate = useNavigate();

  let newItems = [...MANAGER_ITEMS];
  if (program.uses_social_wall > 0) {
    newItems.push({
      title: "Social Wall",
      icon: "spire",
      to: `/manager/nspire-wall`,
    });
  }

  if (program.uses_leaderboards > 0) {
    newItems.push({
      title: "Leaderboard",
      icon: "leaderboard",
      to: `/manager/leaderboards`,
    });
  }

  if (rootProgram && rootProgram?.uses_entrata_pending_approvals === 1) {
    newItems.push({
      title: "Award Approval Pending",
      icon: "budgetcascading",
      to: `/manager/invite-approve`,
    });
  }

  useEffect(() => {
    fetchCascadingApprovalsCount();
    if (organization && program && rootProgram) {
      let cascadingItems = [...newItems];
      if (rootProgram?.use_cascading_approvals > 0 || cascadingApprovalsCount > 0) {
        cascadingItems.push({
          title: "Approvals",
          icon: "budgetcascading",
          to: `/manager/cascading-approvals`,
        });
        setManagerItems(cascadingItems);
      } else {
        setManagerItems(newItems);
      }
    }
  }, [cascadingApprovalsCount,fetchCascadingApprovalsCount, organization , program , rootProgram]);

  if (!program) return "loading...";
  return (
    <div className="tab-navs items-3">
      <ul className="horizontal">
        {managerItems.map((item, key) => {
          return (
            <li key={key} onClick={() => navigate(item.to)}>
              <TabNav
                title={item.title}
                icon={item.icon}
                isActive={item.to == location.pathname}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
ManagerTabNavsTmp =  connect((state) => ({
  organization: state.organization,
  program: state.program,
  rootProgram: state.rootProgram
}))(ManagerTabNavsTmp);

export const ManagerTabNavs = () => {
    return <ManagerTabNavsTmp />
}
