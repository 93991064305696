import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";

import ManagerTabSection from "../components/ManagerTabSection";
import { useTranslation } from "react-i18next";
import Dashboard from "./View/Dashboard";
import Leaderboard from "./View/Leaderboards";
import CartIcon from "mdi-react/CartIcon";
import SocialWallPanel from "@/containers/Participant/Home/socialWall/SocialWallPanel";
import { getAuthProgram } from "@/containers/App/auth";
import { getBalance } from "@/services/program/getBalance";
import { connect } from "react-redux";
import AwardApprovals from "./View/AwardApprovals";
import InviteApprovalPendingCard from "./View/InviteApprovalPending";
import { amountToUsFormat} from '@/shared/helpers'

const Home = ({ program, organization }) => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (organization && program) {
      getBalance(organization.id, program.id)
        .then((data) => {
          setBalance(data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }
  }, [organization, program]);

  return (
    <>
      <Container>
        <ManagerTabSection />
        {Boolean(program.is_pay_in_advance) && <div align="right">Current Balance: {amountToUsFormat(balance,true,"$")}</div>}
      </Container>
      <hr></hr>
      <Dashboard />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    program: state.program,
    organization: state.organization,
    rootProgram: state.rootProgram,
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(Home);

export const NSpireWall = () => {
  const program = getAuthProgram();

  return (
    <>
      {program.uses_social_wall > 0 && (
        <>
          <Container>
            <ManagerTabSection />
          </Container>
          <hr></hr>
          <SocialWallPanel isManager={true} />
        </>
      )}
    </>
  );
};

export const Leaderboards = () => {
  return (
    <>
      <Container>
        <ManagerTabSection />
      </Container>
      <Leaderboard />
    </>
  );
};

const AwardApprovalsIndex = ({ organization, auth, program, rootProgram }) => {
  return (
    <>
      {rootProgram?.use_cascading_approvals > 0 && (
        <>
          <Container>
            <ManagerTabSection />
          </Container>
          <br />
          <div className="m-2 p-2">
            <AwardApprovals  organization={organization} auth={auth} rootProgram={rootProgram} />
          </div>
        </>
      )}
    </>
  );
};

const AwardApproval = connect(mapStateToProps)(AwardApprovalsIndex);
export { AwardApproval };

const InviteApprovalPendingIndex = ({ organization, auth, program, rootProgram }) => {
  return (
    <>
      {rootProgram?.id === 1 && (
        <>
          <Container>
            <ManagerTabSection />
          </Container>
          <br />
          <div className="m-2 p-2">
            <InviteApprovalPendingCard  organization={organization} auth={auth} rootProgram={rootProgram} />
          </div>
        </>
      )}
    </>
  );
};

const InviteApprovalPending = connect(mapStateToProps)(InviteApprovalPendingIndex);
export { InviteApprovalPending };
