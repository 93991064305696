import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { 
  Container, 
} from 'reactstrap';

import SelectProgram from '../components/SelectProgram'
import ProgramParticipants from './components/ProgramParticipants'
import {getBalance} from "@/services/program/getBalance";
import { isCascadingApprovalsEnabled, isBudgetEnabled, amountToUsFormat } from '@/shared/helpers';
import BudgetAndApprovalsSummary from './components/BudgetAndApprovalsSummary';
import { toPoints} from '@/shared/helpers'

const ManageAndReward = ({auth, program, organization, rootProgram}) => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (organization?.id && program?.id) {
      getBalance(organization.id, program.id)
        .then((data) => {
          // console.log(data)
          setBalance(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [organization, program]);

  if( !organization?.id || !program?.id || !rootProgram?.id || !auth ) return 'loading...'

  return (
    <div className="manage-reward">
      <Container>
        <div style={{ color: "white" }}>
          <h3>Manage & Reward</h3>
          {/*<div>You can search for users by name, email or unit/suite number(if applicable), reward users instantly, or deactivate an account.</div>*/}
          {/*<div>You can also select or multiple users from the list to send an email</div>*/}
        </div>
        <div className="my-4 d-flex program-select justify-content-between">
          <div className="d-flex">
            <SelectProgram showRefresh={false} />
          </div>
        </div>
        { Boolean(program.is_pay_in_advance) && <div align="right">Current Balance: {amountToUsFormat(balance,true,"$")}</div> }
        {(isCascadingApprovalsEnabled(rootProgram) || isBudgetEnabled(rootProgram)) && (
          <BudgetAndApprovalsSummary
            organization={organization}
            program={program}
            rootProgram={rootProgram}
          />
        )}

        <ProgramParticipants organization={organization} program={program} auth={auth} rootProgram={rootProgram} balance={balance}/>
      </Container>
    </div>
  );}

const mapStateToProps = (state) => {
  return {
     auth: state.auth,
     program: state.program,
     organization: state.organization,
     rootProgram: state.rootProgram
  };
};

export default connect(mapStateToProps)(ManageAndReward);
