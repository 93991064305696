import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";
import { readAssignedPositionPermissions } from "@/services/program/budget";
import PendingInvites from "@/containers/Manager/Home/components/PendingInvites";

const AwardApprovals = ({ organization, auth, rootProgram }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [assignedPermissions, setAssignedPermissions] = useState([]);

  const togglePan = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    let mounted = true;
    if (auth?.positionLevel && organization?.id && rootProgram?.id) {
      readAssignedPositionPermissions(
        organization.id,
        rootProgram.id,
        auth.positionLevel.id
      )
        .then((res) => {
          if (mounted) {
            setAssignedPermissions(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => (mounted = false);
  }, [auth, organization, rootProgram]);

  return (
    <Container>
      <PendingInvites
        assignedPermissions={assignedPermissions}
      />
    </Container>
  );
};

export default AwardApprovals;
