import React, {useEffect, useMemo, useState} from "react";
import {usePagination, useTable, useRowSelect} from "react-table";
import {
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Container,
} from "reactstrap";
import {
  flashError, flashSuccess, useDispatch,
} from "@/shared/components/flash";
import axios from "axios";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";
import {TABLE_COLUMNS} from "./PendingInviteColumns";
import {connect} from "react-redux";
import apiTableService from "@/services/apiTableService";
import IndeterminateCheckbox from "@/shared/components/form/IndeterminateCheckbox";
import {TableSkeleton, ButtonSkeleton} from "@/shared/components/Skeletons";
import InviteApprovalPopup from "@/containers/Manager/Home/components/InviteApprovalPopup";
import AwardHistoryPopup from "@/containers/Manager/Home/components/AwardHistoryPopup";

const ACTIONS = [{label: "Approve", name: "approved"}, {label: "Reject", name: "reject"},];
const queryPageSize = 10;

const PendingAwardApprovalsTable = ({auth, organization, program, assignedPermissions}) => {
  const [participants, setParticipants] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inviteApprovalParticipants, setInviteApprovalParticipants] = useState([]);
  const [statusName, setStatusName] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isOpenHistory, setOpenHistory] = useState(false);
  const [saving, setSaving] = useState(false);

  const [participant, setParticipant] = useState(null);
  const [events, setEvents] = useState(false);

  const dispatch = useDispatch();

  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  const toggleHistory = async (row) => {
    if (!isOpenHistory) {
      if (row?.original?.userId){
        const response = await axios.get(`/organization/${organization.id}/program/${program.id}/participant/${row.original.userId}`)
        const events = response.data.events;
        if (events > 0) {
          setParticipant(response.data)
          setOpenHistory((prevState) => !prevState);
        }
      }
    } else {
      setParticipant(null)
      setOpenHistory((prevState) => !prevState);
    }
  };

  const SELECTION_COLUMN = {
    id: "selection", Header: ({getToggleAllPageRowsSelectedProps}) => (<div>
      <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    </div>), Cell: ({row}) => (
      row?.original?.events > 0 ?
        <div style={{cursor:'pointer'}} onClick={() => toggleHistory(row)} >-</div>
        : <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      ),
  };

  let final_columns = React.useMemo(() => {
    let column = [];
    column = [SELECTION_COLUMN, ...TABLE_COLUMNS];
    return column;
  }, [assignedPermissions]);

  const onSelectAction = (name) => {
    const rows = selectedFlatRows.map((d) => d.original);
    if (rows.length === 0) {
      alert("Select participants");
      return;
    }
    setInviteApprovalParticipants(rows);
    setStatusName(name);
    toggle();
  };

  const tableInstance = useTable({
    columns: final_columns,
    data: useMemo(() => (participants ? participants?.results : []), [participants]),
    initialState: {
      pageIndex: 0, pageSize: queryPageSize,
    },
    manualPagination: true,
    pageCount: Math.ceil(participants?.count / queryPageSize),
    autoResetSortBy: false,
    autoResetExpanded: false,
    autoResetPage: false,
  }, usePagination, useRowSelect);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    selectedFlatRows,
    state: {pageIndex, pageSize},
  } = tableInstance;

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    if (organization?.id && program?.id) {
      apiTableService
        .fetchData({
          url: `/organization/${organization.id}/program/${program.id}/manager-pending-invites`,
          page: pageIndex,
          size: queryPageSize,
        })
        .then((items) => {
          if (mounted && items) {
            setParticipants(items);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => (mounted = false);
  }, [organization, program, pageIndex, queryPageSize]);

  const ActionsDropdown = () => {
    return (<UncontrolledDropdown>
      <DropdownToggle caret className="dropdowntoggle">
        Actions
      </DropdownToggle>
      <DropdownMenu>
        {ACTIONS.map((item, index) => {
          return (<DropdownItem
            key={`action-dropdown-item-${index}`}
            onClick={() => onSelectAction(item.name)}
          >
            {item.label}
          </DropdownItem>);
        })}
      </DropdownMenu>
    </UncontrolledDropdown>);
  };

  const onSubmit = (values) => {

    try {
      if (inviteApprovalParticipants.length > 0) {
        let formData = {};
        setSaving(true);
        formData.record_id = inviteApprovalParticipants?.map((approvalParticipant) => approvalParticipant.id);
        formData.operation = statusName;
        formData.manager_id = auth.id;
        formData.rejection_note = statusName === "approved" ? `Approved by ${auth.name}` : values.rejection_note;

        axios
          .put(`/organization/${organization?.id}/program/${program?.id}/manager-pending-invites-approve-or-reject`, formData)
          .then((response) => {
            if (response.status === 200) {
              flashSuccess(dispatch, "Updated successfully!");
              setSaving(false);
              toggle();
              window.location.reload();
            }
          })
          .catch((error) => {
            flashError(dispatch, error.message);
            setSaving(false);
          });
      }
    } catch (error) {
      flashError(dispatch, error.message);
    }
  };

  if (loading) {
    return (<>
      <ButtonSkeleton variant="rect" width={100} height={40}/>
      <TableSkeleton
        variant="rect"
        rows={3}
        columns={7}
        width={"100%"}
        height={20}
      />
    </>);
  }
  const manualPageSize = [];
  if (participants) return (<Container>
    <div className="users">
      <div className="header d-flex  justify-content-between">
        <div className="d-flex w-30 justify-content-between dropdown-group">
          <ActionsDropdown/>
        </div>
      </div>
      <div className="points-summary-table">
        <Table striped borderless size="md" {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup) => (<tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (<th {...column.getHeaderProps()}>
              {column.render("Header")}
            </th>))}
          </tr>))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (<tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (<td {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </td>);
              })}
            </tr>);
          })}
          </tbody>
        </Table>
      </div>
    </div>

    <div>
      {rows.length > 0 && (<>
        <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          setPageSize={setPageSize}
          manualPageSize={manualPageSize}
          dataLength={participants ? participants.count : 0}
        />
      </>)}
    </div>
    <InviteApprovalPopup
      isOpen={isOpen}
      setOpen={setOpen}
      toggle={toggle}
      auth={auth}
      organization={organization}
      program={program}
      statusName={statusName}
      inviteApprovalParticipants={inviteApprovalParticipants}
      rejection_notes={`User Rejected by ${auth?.name}`}
      onSubmit={onSubmit}
      loading={saving}
    />
    <AwardHistoryPopup
      isOpen={isOpenHistory}
      setOpen={setOpenHistory}
      toggle={toggleHistory}
      participant={participant}
    />
  </Container>);
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth, program: state.program, organization: state.organization, rootProgram: state.rootProgram,
  };
};
export default connect(mapStateToProps)(PendingAwardApprovalsTable);
