export const TABLE_COLUMNS = [
  {
    Header: "Property Shortcode",
    accessor: "prefix",
    Cell: ({ value }) => {
      return <span>{parseInt(value)?value:''}</span>;
    },
  },
  {
    Header: "First Name",
    accessor: "user_fname",
  },
  {
    Header: "Last Name",
    accessor: "user_lname",
  },
  {
    Header: "Email",
    accessor: "resident_email",
  },
  {
    Header: "Amount",
    accessor: "transaction_amount",
    Cell: ({ value }) => {
      return <span>{value?.toFixed(2)}</span>;
    },
  },
  {
    Header: "Status",
    accessor: "application_type",
  },
  {
    Header: "Lease Approved Date",
    accessor: "lease_approved_on",
    Cell: ({ value }) => {
      let date = new Date(value).toLocaleDateString("en-US", {});
      return <span>{date}</span>;
    },
  },
  {
    Header: "Transaction Date",
    accessor: "transaction_date",
    Cell: ({ value }) => {
      let date = new Date(value).toLocaleDateString("en-US", {});
      return <span>{date}</span>;
    },
  },
  {
    Header: "Unit",
    accessor: "bldg_unit",
  },
  {
    Header: "Charge Code",
    accessor: "charge_code",
  },
  {
    Header: "Note",
    accessor: "entrata_note",
  },
];
