export const TABLE_COLUMNS = [
  {
    Header: "Program Name",
    accessor: "program_name",
    width:120
  },
  {
    Header: "Name",
    accessor: "name",
    width:80
  },
  {
    Header: "Title",
    accessor: "title",
    width:60
  },
  {
    Header: "Email",
    accessor: "contact_email",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Phone",
    accessor: "contact_phone",
  },
];
