import React, { useState, useMemo } from "react";
import { useTable, usePagination, useSortBy, useExpanded, useResizeColumns } from "react-table";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Table } from "reactstrap";
import {QueryClient, QueryClientProvider, useQuery} from 'react-query'
import ReactTablePagination from '@/shared/components/table/components/ReactTablePagination';
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import {getFirstDay, dateStrToYmd, getFirstDayOfMonth} from '@/shared/helpers'
import { MONIES_AVAILABLE_POSTINGS_COLUMNS } from "./columns";
import {reducer, useEffectToDispatch, fetchApiData, TableFilter, Sorting} from "@/shared/apiTableHelper"
// import { getinvoiceNotificationRecipients } from '@/services/invoice/getinvoiceNotificationRecipients'
import { useDispatch, flashError, flashSuccess } from "@/shared/components/flash"

const queryClient = new QueryClient()
const useFilter = false
const filter = null


const getSixMonthDate = () => {
  let currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 6);
  return currentDate;
};

const defaultFrom = getFirstDayOfMonth()
const defaultTo = new Date();

export const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
  queryPageFilter:{},
  queryPageSortBy: [{id:"created_at",desc:true}],
  queryTrigger:0,
};

const options={
  'dateRange':true,
}

const MoniesAvailablePostings = ({ program, organization }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(defaultFrom);
  const [endDate,setEndDate] = useState(defaultTo);
  const [filter,setFilter] = useState({
    start_date:dateStrToYmd(defaultFrom),
    end_date: dateStrToYmd(new Date())
  })
  const [useFilter, setUseFilter] = useState(false);

  const finalFilter = {...filter};

  const onClickFilter = (reset = false) => {
    let data = {};
   if (options.dateRange) {
     data.start_date = dateStrToYmd(reset ? defaultFrom : startDate);
     data.end_date = dateStrToYmd(reset ? defaultTo : endDate);
   }

    onClickFilterCallback(data);
    if (reset) {
      setStartDate(defaultFrom);
      setEndDate(defaultTo);
    }
  };

  const onClickFilterCallback = (values)=>{
    let change = false;
    if (options.dateRange) {
      if (
        finalFilter.start_date !== values.start_date ||
        finalFilter.end_date !== values.end_date
      ) {
        change = true;
      }
    }
    if (!change) {
      alert("No change in filters");
      setUseFilter(false);
      return;
    }
    let filters = {};

    filters.start_date = values.start_date;
    filters.end_date = values.end_date;
    setFilter(filters);
    setUseFilter(true);
  }

  const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryTrigger }, dispatch] =
  React.useReducer(reducer, initialState);

  const apiUrl = `/organization/${organization.id}/program/${program.id}/monies-available-postings`;
  const {isLoading, error, data, isSuccess, isFetching} = useQuery(
    ['Name_MoniesAvailablePostings', apiUrl, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryTrigger],
    () => fetchApiData(
      {
        url: apiUrl,
        page: queryPageIndex,
        size: queryPageSize,
        filter,
        sortby: queryPageSortBy,
        trigger: queryTrigger
      }
    ),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  let columns = useMemo(() => MONIES_AVAILABLE_POSTINGS_COLUMNS, [])
  const totalPageCount = Math.ceil(totalCount / queryPageSize)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    rowSpanHeaders,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: {pageIndex, pageSize, sortBy}
  } = useTable({
      columns: columns,
      data: data ? Object.values(data.results) : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true, // Tell the usePagination
      pageCount: data ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
      disableResizing: true,
      autoResetHiddenColumns: false,
      striped: true
    },
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns,
    // useFlexLayout,
  );

  const manualPageSize = []
  useEffectToDispatch(dispatch, {pageIndex, pageSize, gotoPage, sortBy, filter, data, useFilter});

  const onStartDateChange = (date) => {
     setStartDate(date);
  };

  const onEndDateChange = (date)=>{
    setEndDate(date)
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {JSON.stringify(error)}</p>;
  }
  
  if (isSuccess)
    return (
      <>
      <Row className="d-flex justify-content-center align-items-center pl-1">
          <Col className="d-flex gap-5 align-items-center pl-1">
            <div className="table-filter-form-col table-filter-form-col2 float-filter">
              <div className="form__form-group">
                <span className="form__form-group-label">From</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-row">
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={startDate}
                      onChange={onStartDateChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-filter-form-col table-filter-form-col2 float-filter">
              <div className="form__form-group">
                <span className="form__form-group-label">To</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-row">
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={endDate}
                      onChange={onEndDateChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="d-flex gap-5 align-items-center mt-4">
            <Button
              onClick={() => onClickFilter()}
              className="btn btn-sm btn-primary"
              color="#ffffff"
            >
              Filter
            </Button>
            <Button
              onClick={() => onClickFilter(true)}
              className="btn btn-sm btn-primary"
              color="#ffffff"
            >
              Reset
            </Button>
          </Col>
        </Row>
        {isFetching && <p>Loading...</p>}
        <Table striped borderless size="md" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {column.isSorted ? <Sorting column={column}/> : ''}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {(rows.length > 0) && (
          <>
            <ReactTablePagination
              page={page}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              setPageSize={setPageSize}
              manualPageSize={manualPageSize}
              dataLength={totalCount}
            />
          </>
        )}
      </>
    );
};

const TableWrapper = ({organization, program}) => {
  if (!organization || !program) return 'Loading...'
  return (
    <QueryClientProvider client={queryClient}>
      <MoniesAvailablePostings organization={organization} program={program}/>
    </QueryClientProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    program: state.program,
    organization: state.organization,
  };
};
export default connect(mapStateToProps)(TableWrapper);
