import React, { useEffect, useMemo, useState } from "react";
import {
  useExpanded,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";
import { Col, Row } from "reactstrap";
import { dateStrToYmd, getFirstDay } from "@/shared/helpers";
import { TABLE_COLUMNS } from "./columns";
import { connect } from "react-redux";
import {
  reducer,
  useEffectToDispatch,
  fetchApiData,
  fetchApiDataExport,
  initialState,
  Sorting,
} from "@/shared/apiTableHelper";
import { clone } from "lodash";
import TeamRosterFilter from "./TeamRosterFilter";

const queryClient = new QueryClient();

const DataTable = ({ organization, program, programs }) => {
  const [filter, setFilter] = useState({
    programs: programs,
    // createdOnly: false,
    programId: program.id,
    from: dateStrToYmd(getFirstDay()),
    to: dateStrToYmd(new Date()),
  });
  const [useFilter, setUseFilter] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportHeaders, setExportHeaders] = useState([]);
  const [exportToCsv, setExportToCsv] = useState(false);
  const exportLink = React.createRef();

  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryTrigger,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState);

  const apiUrl = `/organization/${organization.id}/report/team-roaster`;
  const { isLoading, error, data, isSuccess } = useQuery(
    [
      "",
      apiUrl,
      queryPageIndex,
      queryPageSize,
      queryPageFilter,
      queryPageSortBy,
      queryTrigger,
    ],
    () =>
      fetchApiData({
        url: apiUrl,
        page: queryPageIndex,
        size: queryPageSize,
        filter,
        sortby: queryPageSortBy,
        trigger: queryTrigger,
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (exportToCsv) {
      if (exportLink.current) {
        setExportToCsv(false);
        exportLink.current.link.click();
      }
    }
  }, [exportLink]);

  const download = async (filterValues) => {
    let tmpFilter = clone(filterValues);
    tmpFilter.exportToCsv = 1;
    tmpFilter.server = "program";

    const response = await fetchApiDataExport({
      url: apiUrl,
      filter: tmpFilter,
      sortby: queryPageSortBy,
      trigger: queryTrigger,
    });
    setExportData(response.results);
    setExportHeaders(response.headers);
    setExportToCsv(true);
  };

  let columns = useMemo(() => TABLE_COLUMNS, []);

  const totalPageCount = Math.ceil(totalCount / queryPageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    rowSpanHeaders,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns: columns,
      data: data ? data.results : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true, // Tell the usePagination
      pageCount: data ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
      disableResizing: true,
      autoResetHiddenColumns: false,
      striped: true,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns
    // useFlexLayout,
  );

  const manualPageSize = [];
  useEffectToDispatch(dispatch, {
    pageIndex,
    pageSize,
    gotoPage,
    sortBy,
    filter,
    data,
    useFilter,
    trigger,
  });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {JSON.stringify(error)}</p>;
  }

  if (isSuccess)
    return (
      <>
        <div className="table react-table report-table">
          <div className="action-panel">
            <Row className="mx-0">
              <Col>
                <TeamRosterFilter
                  filter={filter}
                  setFilter={setFilter}
                  useFilter={useFilter}
                  setUseFilter={setUseFilter}
                  exportData={exportData}
                  exportLink={exportLink}
                  exportHeaders={exportHeaders}
                  download={download}
                />
              </Col>
            </Row>
          </div>
          {isLoading && <p>Loading...</p>}
          {isSuccess && (
            <table
              {...getTableProps()}
              className="table table-striped report-table"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {column.isSorted ? <Sorting column={column} /> : ""}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="table table--bordered" {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={i}>
                      {row.cells.map((cell, i) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={i}
                            style={{ maxWidth: "150px" }}
                          >
                            <span>{cell.render("Cell")}</span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                {footerGroups.map((footerGroup) => (
                  <tr {...footerGroup.getFooterGroupProps()}>
                    {footerGroup.headers.map((column) => (
                      <th {...column.getFooterProps()}>
                        {column.render("Footer")}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </table>
          )}

          {rows.length > 0 && (
            <>
              <ReactTablePagination
                page={page}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                pageIndex={pageIndex}
                pageCount={pageCount}
                setPageSize={setPageSize}
                manualPageSize={manualPageSize}
                dataLength={totalCount}
              />
            </>
          )}
        </div>
      </>
    );
};

const TableWrapper = ({ organization, program, programs }) => {
  if (!organization || !program) return "Loading...";
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable
        organization={organization}
        program={program}
        programs={programs}
      />
    </QueryClientProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    program: state.program,
    organization: state.organization,
  };
};
export default connect(mapStateToProps)(TableWrapper);
